.inline-first-p > p {
}

.max-w-lg-2 {
  max-width: 45rem;
}

.error_msg {
  border-left: 5px solid red;
  border-radius: 2px;
  background-color: #ffe3e3;
  padding: 0 10px;
}

.warning_msg {
  border-left: 5px solid orange;
  border-radius: 2px;
  background-color: rgb(251, 228, 185);
  padding: 0 10px;
  margin-top: 3%;
}

.user_role_msg {
  text-align: center;
  background-color: #ffeee3;
  text-transform: uppercase;
  color: #b64a0b;
  font-weight: 500;
  width: 99%;
  margin-top: -18%;
  border-radius: 20px;
  font-size: 16px;
  height: 30px;
  padding: 5px 0 0 0;
}

.deleteUI {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.deleteUI_button {
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  align-items: center;
}

.PaginationUI {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.jodit-status-bar__item .jodit-status-bar__item-right {
  display: none !important;
}

.justification_cs td {
  border: 1px solid #dfdede !important;
  padding: 17px !important;
}

td {
  border-bottom: solid 1px rgb(223, 222, 222);
}

.tableTd {
  border-bottom: solid 1px rgb(223, 222, 222) !important;
}

.sidebarDiv {
  background-color: #08285b;
}

.searchUI {
  width: 50%;
}

.iconAlign {
  margin-top: 7px;
  margin-left: 90%;
}

.iconColoor {
  color: #a3a3a3 !important;
}

.tableUI {
  border: none !important;
}

::-webkit-scrollbar {
  height: 10px;
  width: 5px;
}
::-webkit-scrollbar-button {
  background: #ccc;
  border-radius: 10px;
}
::-webkit-scrollbar-track-piece {
  margin-left: 10px;
  background: #eee;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}


.inputUI {
  padding-left: 3rem !important;
}

.paginationActive {
  background-color: #3730a3;
  color: white;
  border-radius: 100%;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.paginationInactive {
  background-color: none;
  color: #111827;
}

.pagButton:hover {
  width: 100%;
  background-color: #3730a3 !important;
  color: white;
  border-radius: 20px;
  padding: 8px 15px !important;
}

.pagBackground {
  border-radius: 10px;
  height: 60px;
}

.ContainerUI {
  padding-left: 2% !important;
  padding-right: 2% !important;
}

.tableShadow {
  box-shadow: 5px 5px 18px #eeeded !important;
}

.mainFormBody {
  width: 45%;
  height: 94vh;
}

.formContent {
  height: 60rem !important;
}

.Signin_logo {
  width: 30%;
  margin-top: 5%;
  margin-left: 18%;
}

.formContainer {
  width: 219%;
  margin-top: 11rem;
}

.SignInForm {
  margin-left: 20%;
  margin-right: 20%;
}

.LoginSubText {
  font-size: 16px;
  color: #667085;
  margin-top: 10px;
}

.loginFontSize {
  font-size: 18px;
  line-height: 40px;
}

.enrolmentFontSize {
  font-size: 15px;
  line-height: 30px;
}

.error_text {
  font-size: 18px;
  line-height: 30px;
  font-weight: 500;
}

@media only screen and (max-width: 800px) {
  .mainFormBody {
    width: 100% !important;
  }

  .formContent {
    height: 50rem !important;
  }
  .Signin_logo {
    width: 50%;
    margin: 5% auto;
  }

  .formContainer {
    display: flex;
    width: 100%;
    margin-top: 6rem;
    justify-content: center;
  }

  .LoginMainText {
    text-align: center;
  }
  .LoginSubText {
    text-align: center;
  }

  .SignInForm {
    margin-left: 2%;
    margin-right: 2%;
  }

  .dashboardUI {
    height: 600px !important;
  }
}

.MainDiv {
  height: 780px !important;
  border-radius: 20px;
  gap: 4%;
}

.SubDiv1 {
  border-radius: 20px;
  width: 20%;
  height: 20%;
  box-shadow: 3px 2px 5px 0px #dbdbdb;
  background-color: #f2f2f2;
}

.inner-text-1 {
  font-family: inherit;
  font-size: 25px;
  margin-left: 10%;
  margin-top: 5%;
  color: #3730a3;
  opacity: 0.7;
  font-weight: 700;
}

.inner-text-2 {
  font-size: 45px;
  text-align: center;
  color: #3730a3;
}

.dashboardTitle {
  font-size: 40px;
  padding-bottom: 10px;
}

.dashboardSubTitle {
  padding-left: 5px;
}

.toggleButton {
  margin-right: 65%;
}

.success_msg {
  border-left: 5px solid #06b100;
  border-radius: 2px;
  background-color: #06b1001c;
  padding: 0 10px;
  margin-top: 3%;
  color: #256c43;
}

.pop_close_icon {
  margin-bottom: 50%;
}

.nrich_enrolment_logo {
  width: 15%;
  margin-top: 1%;
  margin-left: 1%;
}

.submit_btn {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.hdg-label-popup {
  display: none;
  position: absolute;
}

.icon:hover ~ .hdg-label-popup {
  display: block;
}

.center {
  width: 30%;
  height: 10%;
}

.submitBtn {
  margin-top: 4%;
  width: 100%;
  height: 40px;
}

.submitdiv {
  text-align: center;
}

.imageSize {
  width: 70%;
}

.nrichTable tr td {
  padding-left: 30px;
  padding-top: 20px;
}

.infoPage {
  display: flex;
}

.mobileForm {
  display: none;
}

@media screen and (max-width: 991px) {
  .infoPage {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .mobileForm {
    display: flex !important;
  }
}

.title_color {
  background-color: none;
  z-index: 10;
}

.fixed-h1-margin {
  margin-left: 2%;
}

.fixed-button-margin {
  margin-right: 1%;
}

.examStatusUI {
  width: 5%;
  height: 2%;
  background-color: green;
  border-radius: 30px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.container-snap::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.container-snap {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}


.fontStandard * {
  font-family: Arial !important;
  font-size: 22px !important;
  text-align: justify;
}

.fontStandard td {
  border: 1px solid black;
  padding: 10px;
  text-align: left;
}

.user_role{
  color: #b64a0b;
  background-color: #6d7a9a;
  text-transform: uppercase;
  border-radius: 20px;
}

.big_slideover {
  width: 85vw;
}

.half_slideover {
  width: 50vw;
}

.minHeight{
  min-height: 60vh !important;
}

.customDatePickerWidth, 
.customDatePickerWidth > div.react-datepicker-wrapper, 
.customDatePickerWidth > div > div.react-datepicker__input-container
.customDatePickerWidth > div > div.react-datepicker__input-container input {
   width: 100%;
}

.fixedHeight{
  height: 45em;
  overflow-y: auto;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
  position: absolute;
  padding: 0.7rem;
  box-sizing: content-box;
}

.math-quill-container .h1,
.math-quill-container p {
  font-family: Lato;
}

.math-quill-container .mq-editable-field {
  width: 100%;
  height: 65px;
  padding: 10px;
  font-size: 20px;
}

.math-quill-container .tex-button {
  width: 50px;
  height: 50px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.math-quill-container .tex-button svg {
  width: 30px;
  height: 30px;
}

.left-aligned-editor
{
  text-align: left !important;
}