.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* .invoice p {
  font-weight: 500;
  color:#444;
  font-size: 14px!important;
  line-height: 30px;
} */

.invoice .table>:not(:last-child)>:last-child>*{
  border-bottom: 0px;
  background-color: rgb(231, 231, 231);
}
.invoice .fs-7, th,td{
  font-size: 14px;
}
.invoice .w-a4{
  width: 750px;
}

.invoice .p-1{
  padding:.25rem!important;
}
.fs-5{
  font-size: 20px;
}

.jodit-add-new-line{
  display: none !important;
}

.css-1wvake5.ps-collapsed{
  width: 0px !important;
  min-width:  0px !important;
}
.react-tel-input .form-control{
  width:100% !important;
  border: none !important;
  padding:0 0 0 48px!important;
  font-size: 0.875rem;
    line-height: 1.25rem;

}
.react-tel-input .form-control::placeholder{
  color: #cbd5e0!important;
}
.react-tel-input{
  padding:0 0 0 0px!important;
}
.disable-text-selection{
  -moz-user-select:none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
}

input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}